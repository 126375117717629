<template>
  <div class="Home">
    <page-header v-show="able == false"/>
    <section class="uk-section uk-padding-small uk-margin-top" style="min-height: 300px;" v-show="able == false">
      <div class="uk-container uk-margin-bottom">
        <div class="uk-width-1-1 uk-margin-remove" uk-grid>
          <div class="uk-width-2-5 uk-padding-remove">
            <input type="text" class="uk-input" :placeholder="$t('sName')" v-model="surTitleSearch"/>
          </div>
          <div class="uk-width-2-5">
            <input type="text" class="uk-input" :placeholder="$t('sContent')" v-model="surContentSearch"/>
          </div>
          <div class="uk-width-1-5 uk-flex uk-flex-right">
            <button type="button" class="qbtn" @click="loaddata(currentPage)">
              {{$t('search')}}
            </button>
            <button type="button" class="qbtn uk-margin-left" style="width: 160px" @click="defSearch()">
              {{$t('dSearch')}}
            </button>
          </div>
        </div>
      </div>
      <div class="uk-container">
        <div class="uk-card uk-card-default uk-box-shadow-medium">
          <table class="uk-table uk-table-divider uk-table-responsive uk-table-small">
            <thead>
            <tr>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center" style="width: 190px;">{{$t("survey.title")}}</th>
              <th class="uk-text-center" style="width: 190px;">{{$t("survey.content")}}</th>
              <th class="uk-text-center">{{$t("survey.tQues")}}</th>
              <th class="uk-text-center">{{$t("survey.comp")}}</th>
              <!-- <th class="uk-text-center">Registrant id</th> -->
              <th class="uk-text-center" colspan="2">{{$t("survey.details")}}</th>
            </tr>
            </thead>
            <tbody class="uk-text-center" v-if="surResultList.length > 0">
            <tr v-for="(item, index) in surResultList" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.title}}</td>
              <td>{{item.content}}</td>
              <td>{{item.qcount}}</td>
              <td>{{item.rescount}}</td>
              <!-- <td>{{item.id}}</td> -->
              <td style="width: 60px;">
                <button
                    class="qbtn uk-width-1-1 uk-button uk-button-small"
                    @click="resultDetail(item.id)">
                  {{$t('details')}}
                </button>
              </td>
              <td style="width: 60px;">
                <button class="qbtn uk-width-1-1 uk-button uk-button-small"
                        @click="surExamList(item.id)">
                  {{$t('examlist')}}
                </button>
              </td>
            </tr>
            </tbody>
            <tbody  v-else>
            <td class="uk-text-center" colspan="7" style="background-color: #fff;
                color: black"><h4 >{{$t("survey.noSur")}}</h4></td>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- model view result section -->
    <div id="viewResult" class="uk-modal-container" uk-modal :class="{'ableFix' : able == true}">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close v-if="able == false"></button>
        <div class="uk-modal-header" v-if="able == false">
          <div class="qList uk-flex uk-margin-top uk-margin-bottom">
            <h4>{{surTitle}}</h4>
            <div class="uk-flex" style="position: absolute; right: 53px;">
              <button class="qbtn_j uk-button uk-button-small"
                      @click="downloadResult('0')" >
                <span uk-icon="download" class="uk-margin-small-right"></span>
                {{$t('survey.download')}}
              </button>
              <button class="qbtn_j uk-button uk-button-small uk-margin-left" @click="printAll()">
                <span uk-icon="print" class="uk-margin-small-right"></span>
                {{$t('survey.print')}}
              </button>
            </div>
          </div>
        </div>
        <div :class="{'uk-modal-body': able == false }">
          <!--           && able == false -->
          <div class="uk-width-1-1">
            <div class="uk-card" v-for="(item,index) in surQuestions" :key="index"
                 :class="{'onePage': able == true }">
              <div class="border-blue uk-margin-remove-left uk-flex uk-margin-bottom"
                   v-show="surContent != '' && index == 0">
                <h4 class="uk-margin-remove" style="white-space: nowrap;">{{ $t('sContent') }}: </h4>
                <span class="uk-margin-remove-top uk-h4 uk-margin-left">
               {{surContent}}</span>
              </div>
              <div class="uk-margin-bottom">
                <span class="orderQuestion">{{index+1}}</span><span style="margin-left: 15px">{{item.question}}</span>
                <span v-if="item.type == 0" class="uk-margin-small-left uk-text-success">({{ $t('survey.surSing') }})</span>
                <span v-else-if="item.type == 2" class="uk-margin-small-left uk-text-success">({{ $t('survey.surMul') }})</span>
                <span v-else class="uk-margin-small-left uk-text-success">({{ $t('survey.surSh') }})</span>
              </div>
              <div class="uk-margin-bottom" v-if="item.type == 1">
                <div class="uk-card uk-card-body" style="border: 1px solid #dee2fe; border-radius: 15px; min-height: 200px;">
                  <div v-for="(sans, sIndex) in item.short_answers" :key="sIndex">
                    <div style="border: 1px solid #dee2e6; margin-top: 5px; padding: 5px;">
                      <span>{{sIndex+1}}.</span>
                      <span class="uk-margin-small-left">{{sans.answers}}</span>
                      <span class="uk-text-success uk-margin-small-left">({{sans.examinee_name}})</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin-remove-left" uk-grid v-else>
                <div :class="{'uk-width-1-2' : able == false }" style="min-height: 200px;">
                  <div id="chart">
                    <apexchart type="bar" height="350" :options="item.chartOptions" :series="item.series"></apexchart>
                  </div>
                </div>
                <div :class="{'uk-width-1-2' : able == false }">
                  <div v-for="(ans, ansIndex) in item.answers" :key="ansIndex"
                       class="uk-margin-bottom">
                    <span class="orderAnswer">{{ansIndex+1}}</span>
                    <span class="ansAnswers">{{ans.answer}}</span>
                    <span class="answerCheck">({{ans.checkedCount}})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="examResultList" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog uk-modal-body" style="width: 950px;">
        <div class="qList uk-text-center">
          <div>
            <h4>{{surTitle}}</h4>
          </div>
        </div>
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-width-1-1" style="max-height: 500px; margin-top: 20px; overflow-x: hidden; overflow-y: auto;">
          <table class="uk-table uk-table-responsive">
            <thead>
            <tr style=" background: rgb(1, 176, 255); color: #fff;">
              <th class="listTitle">
                <span>No</span>
              </th>
              <th class="listTitle">
                <span>
                  {{ $t('examName') }}
                </span>
              </th>
              <th class="listTitle">
                  <span>
                    {{ $t('rCount') }}
                  </span>
              </th>
              <th class="listTitle" style="width: 130px">
                <span> {{ $t('downSurvey') }} </span>
              </th>
              <!--              <th class="listTitle" style="width: 120px;">-->
              <!--                <span>...</span>-->
              <!--              </th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in examList" :key="index">
              <td class="listTitle">
                <span>{{index+1}}</span>
              </td>
              <td class="listTitle">
                <span>{{item.exam_name}}</span>
              </td>
              <td class="listTitle">
                <span>{{item.res_count}}</span>
              </td>
              <td class="listTitle">
                <div style="display: flex">
                  <button class="qbtn uk-width-1-1 uk-button uk-button-small"
                          @click="downloadResult(item.exam_id)"
                          style="width: 150px; margin-left: 10px">{{$t('details')}}
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="examQuestions" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog uk-modal-body" style="width: 950px;">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-width-1-1" style="height: 500px; margin-top: 20px;">
          <table class="uk-table uk-table-responsive">
            <div class="uk-width-1-1 uk-margin-remove-left uk-text-center uk-margin-bottom eQuestion" uk-grid
                 style="display: flex">
              <span class="uk-width-1-4 top">Survey name: </span>
              <p class="uk-width-1-4 uk-margin-remove right">{{examSurveyQuestion.surveyTitle}}</p>
              <span class="uk-width-1-4 top2">Exam name: </span>
              <p class="uk-width-1-4 uk-margin-remove right">{{examSurveyQuestion.examName}}</p>
            </div>
            <tbody v-for="(qItem,qindex) in examSurveyQuestion.questions" :key="qindex">
            <div class="uk-subnav uk-text-center questionNumber">
              <span class="quesOrder">{{qindex+1}}</span>
              <p class="surQuestion">{{qItem.question}}</p>
            </div>
            <div v-for="(item,codeIndex) in qItem.answers" :key="codeIndex">
              <td class="uk-subnav">
                <p class="uk-margin-remove">{{codeIndex+1}}</p>
                <span class="surAnswer">{{item.answer}}</span>
                <span style="color: #3456be; margin-left: 15px; font-size: 18px;">({{item.checkedCount}})</span>
              </td>
            </div>
            </tbody>
          </table>
        </div>
        <div class="uk-modal-footer uk-padding-remove uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close"
          >{{ $t('cancel') }}
          </button>
          <button type="button" class="uk-button uk-button-primary" >{{ $t('okay') }}</button>
        </div>
      </div>
    </div>
    <div id="surveyDownload" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">{{ $t('srDownload') }}</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            {{ $t('reFile') }} <br>
            {{ $t('pClick') }}
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t('cancel') }}</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                  @click="closeModal()">{{ $t('survey.download') }}
          </button>
        </div>
      </div>
    </div>
    <div id="examineeDownload" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">{{ $t('srDownload') }}</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            {{ $t('reFile') }} <br>
            {{ $t('pClick') }}
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">
            {{ $t('close') }}
          </button>
          <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                  @click="closeModal()">{{ $t('survey.downlaod') }}
          </button>
        </div>
      </div>
    </div>
    <section class="uk-section uk-padding-remove"  v-show="able == false">
      <div class="uk-container">
        <div class="uk-card-footer uk-text-center">
          <div class="uk-flex uk-flex-center">
            <v-pagination
                v-model="currentPage"
                :page-count="pages"
                :classes="uikitClasses"
                :labels="Labels"
                @change="onChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </section>
    <page-footer v-show="able == false"/>
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import vPagination from "@/components/globals/vue-plain-pagination"
import ResearchService from "../../services/ResearchService";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
import UIkit from "uikit";
import VueApexCharts from "vue-apexcharts"

export default {
  name: "app",
  components: {
    vPagination,
    PageHeader,
    PageFooter,
    apexchart: VueApexCharts
  },
  computed: {
    ...mapGetters(["isLoggedIn", "currentUser"])
  },
  data() {
    return {
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "Last"
      },
      able: false,
      currentPage: 1,
      pages: 0,
      surContent: '',
      surTitle: '',
      surTitleSearch: '',
      surContentSearch:'',
      surQuestions: [],
      surResultList: [],
      examList: [],
      surveyId: '',
      examSurveyQuestion: {},
      surveyUrl: ''
    };
  },
  mounted() {
    // this.renderChart(this.chartdata, this.options)
  },
  created() {
    this.loaddata();
  },
  methods: {
    printAll(){
      this.able = true
      // var _this = this
      setTimeout(function (){
        window.print();
        // _this.able = false;
        window.location.reload();
      }, 500);
    },
    closeModal() {
      UIkit.modal('#surveyDownload').hide()
      window.location.href = '/uploadingDir/surveyreport/' + this.surveyUrl
    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        let item = {
          page: page,
          title: this.surTitleSearch,
          content: this.surContentSearch,
        }
        const response = await ResearchService.surveyResultList(item);
        this.surResultList = response.data.content;
        this.pages = response.data.totalPages;
      } catch (error) {
        console.log(error);
      }
    },
    async surExamList(id) {
      try {
        this.surveyId = ''
        this.surTitle = ''
        this.examList = []
        this.surveyId = id
        let item = {
          researchId: id,
        };
        const response = await ResearchService.surveyExamList(item);
        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.examList = response.data.result.exams
            this.surTitle = response.data.result.title
            UIkit.modal('#examResultList').show()
          }
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async surExamDetails(id) {
      try {
        let item = {
          examId: id,
          researchId: this.surveyId
        };
        const response = await ResearchService.surveyExamDetail(item);

        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.examSurveyQuestion = response.data.result

            UIkit.modal('#examQuestions').show()
          } else {
            alert(response.data.message)
          }
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async downloadResult(id) {
      try {
        let item = {
          examId: id,
          researchId: this.surveyId
        }
        const response = await ResearchService.surveyResultDownload(item);
        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.surveyUrl = response.data.result.downloadUrl
            // window.location.href = 'https://ubtcloud.me/uploadingDir/surveyreport/' + this.surveyUrl
            // window.print()
            UIkit.modal('#surveyDownload').show()
          }
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error)
      }
    },
    async resultDetail(id) {
      try {
        this.surTitle = ''
        this.surContent = ''
        this.surQuestions = []
        this.surveyId = id
        let item = {
          researchId: id,
        };
        const response = await ResearchService.surveyResultDetail(item);
        if (response.data.status == 200) {
          if (response.data.success == true) {

            UIkit.modal('#viewResult').show()
            this.surTitle = response.data.result.surveyTitle
            this.surContent = response.data.result.surveyContent
            this.surQuestions = response.data.result.questions
            for (let it = 0; it < this.surQuestions.length; it++)
            {
              let ques = this.surQuestions[it]
              ques.chartOptions = {
                chart: {
                  type: 'bar',
                  height : 350
                },
                plotOptions: {
                  bar: {
                    borderRadius : 4,
                    horizontal : false,
                  }
                },
                dataLabels: {
                  enabled: true
                },
                xaxis: {
                  categories: [],
                }
              },
                  ques.series = [{
                    data: []
                  }],
                  ques.totalChecked = 0
              // var categories = {}
              for (let j = 0; j < this.surQuestions[it].answers.length; j++)
              {
                let detail = this.surQuestions[it].answers[j]
                ques.series[0].data.push(detail.checkedCount);
                var gogo = detail.answer
                ques.chartOptions.xaxis.categories.push(gogo)
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChange: function () {
      this.loaddata(this.currentPage);
    },
    defSearch() {
      window.location.reload()
    }
  }
};
</script>
<style scoped>
.ableFix{
  /*position: absolute;*/
  overflow: auto;
}
.ableFix .uk-modal-dialog{
  opacity: 1;
}
.listTitle {
  text-align: center;
  font-weight: 600;
  font-size: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.eQuestion {
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  font-weight: 500;
}

.eQuestion .top {
  background: #0f7ae5;
  padding: 10px;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.eQuestion .top2 {
  background: #0f7ae5;
  padding: 10px;
  color: white;
}

.eQuestion .right {
  padding: 10px;
  color: #000;
  text-transform: capitalize;
}

div div h4 {
  font-weight: 700;
  text-transform: capitalize;
  color: #3456be;
}
.uk-modal-dialog {
  border-radius: 15px;
}
.uk-table td {
  border: 1px solid #deedfe;
}

.uk-modal-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.uk-modal-footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.uk-modal-header .uk-modal-title {
  font-size: 23px;
  text-transform: capitalize;
  font-weight: 700;
  color: #0f7ae5;
}

.quesOrder {
  border: 1px solid #dee2e6;
  border-radius: 50%;
  padding: 3px;
  width: 27px;
  margin: 5px;
}

.surQuestion {
  margin: 0px;
  padding: 9px;
}

.surAnswer {
  margin-left: 10px;
  font-size: 17px;
}

.qList {
  height: 30px;
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.qList h4{
  font-size: 1.4em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

div input {
  border-radius: 25px;
  text-align: center;
}

.qbtn {
  display: inline-block;
  width: 107px;
  color: rgb(1, 176, 255);
  border: 1px solid rgb(1, 176, 255);
  background: #fff;
  border-radius: 1em;
  text-transform: capitalize;
  text-align: center;
  outline: none;
}
.qbtn_j {
  display: inline-block;
  width: 160px;
  color: rgb(1, 176, 255);
  border: 1px solid rgb(1, 176, 255);
  background: #fff;
  border-radius: 1em;
  text-transform: capitalize;
  text-align: center;
  outline: none;
}
.qbtn:hover {
  background: rgb(1, 176, 255);
  color: white;
}

.questionNumber {
  font-size: large;
  padding: 0px 10px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  color: #011638;
}
.border-blue{
  border-radius: 15px;
  border-color: #0d9df9;
  padding: 15px;
}
.text-h4{
  font-weight: bold;
  color: #0d9df9;
}
.orderQuestion {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 50%;
  width: 150px !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.orderAnswer {
  border: 1px solid #dee2e6;
  border-radius: 50%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.uk-section{
  min-height: 300px;
}
.uk-width-2-5{
  height: 40px;
}
input{
  height: 40px;
}
.onePage{
  page-break-before: always;
  margin-top: 100px;
}
</style>
